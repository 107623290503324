<template>
  <div class="input-with-label" :class="{ '__label-left': labelLeft}">
    <div v-if="label" class="input-with-label__label">
      <span
        v-if="required"
        class="input-with-label__required"
      >
        *
      </span>
      {{label}}
      <slot name="label"></slot>
      <TooltipIcon
        v-if="tooltip"
        :title="tooltip.title"
        :text="tooltip.text"
      />
    </div>
    <slot name="input">
      <base-input
        :value="value"
        :fullWidth="true"
        :disabled="disabled"
        :placeholder="placeholder"
        :invalid="invalid"
        :errors="errors"
        :type="type"
        :sign="sign"
        :iconName="iconName"
        @input="$emit('input', $event)"
        @blur="$emit('blur', $event)"
      />
    </slot>
  </div>
</template>

<script>
import TooltipIcon from './TooltipIcon';

export default {
  name: 'InputWithLabel',
  components: {
    TooltipIcon,
  },
  props: {
    value: {
      type: [ String, Number ],
    },
    label: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
    valid: {
      type: Boolean,
    },
    errors: {
      type: Array,
    },
    type: {
      type: String,
      default: 'text',
    },
    sign: {
      type: String,
    },
    iconName: {
      type: String,
      default: '',
    },
    labelLeft: {
      type: Boolean,
    },
  },
  computed: {
    invalid () {
      return this.errors ? this.errors.length > 0 : false
    },
  },
}
</script>

<style lang="scss" scoped>
.input-with-label {
  padding-bottom: 25px;
  position: relative;

  &__label {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 9px;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  &__required {
    color: $color-error;
    margin-right: 5px;
  }

  &.__label-left {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 6fr;
    grid-gap: 15px;
    align-items: start;
  }

  &.__label-left &__label {
    margin: 0;
    align-self: center;
  }
}
</style>
